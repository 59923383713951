export const createCrumb = (crumb, slugPath, isHTML = false) => ({
  altDesc: `View All ${crumb} Products`,
  id: slugPath,
  slug: slugPath,
  text: crumb,
  isHTML,
})

export const generatePDPCrumbs = (breadcrumb, title) => {
  const crumbs = []
  if (breadcrumb?.breadcrumb_label && breadcrumb?.breadcrumb_url) {
    crumbs.push(createCrumb(breadcrumb.breadcrumb_label, breadcrumb.breadcrumb_url))
  }
  if (title) {
    crumbs.push(createCrumb(title, typeof window !== 'undefined' ? window.location.pathname : '', true))
  }
  return {
    crumbs,
  }
}

export const scrollToBonusBuys = (ref, isMobile) =>
  typeof window !== 'undefined' && ref.current
    ? window.scrollTo(0, ref.current.offsetTop - (isMobile ? 120 : 220))
    : null

export const base64urlDecode = str => {
  let baseStr = str.replace(/-/g, '+').replace(/_/g, '/')
  baseStr = str.padEnd(str.length + ((4 - (str.length % 4)) % 4), '=')
  return atob(baseStr)
}

export const decodeJWT = token => {
  if (token) {
    const parts = token.split('.')
    if (parts.length !== 3) {
      throw new Error('Invalid JWT')
    }
    const payload = JSON.parse(base64urlDecode(parts[1]))
    return payload
  }
  return null
}

export const formatTurntoJWTPayload = payload => {
  if (!payload) return { validationErrors: true, ugcId: null }
  payload.ugcId ??= null
  payload.alreadyReviewed ??= null
  return payload
}
