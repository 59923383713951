import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery, Divider, useTheme } from '@mui/material'
import { getRegionZone } from '@helpers/geo-location'
import ProductTile from '@components/product/product-tile'
import SimpleSlider from '@components/shared/slider'
import { fetchProductBySku } from '@services/product'
import ReviewProductInfo from './ReviewProductInfo'
import PastOrders from './PastOrders'
import {
  SliderWrapper,
  SuccessIcon,
  SuccessTypography,
  SuccessWrapper,
  Wrapper,
  YouMayAlsoLikePaper,
  YouMayAlsoLikeTypography,
} from './styled'

const ReviewProductSuccess = ({ pastOrders, searchParams, dataTestIdPrefix, prodImageURL, prodSKU, prodTitle }) => {
  const { palette } = useTheme()
  const [carouselSlides, setCarouselSlides] = useState([])
  const [sku, setSku] = useState(prodSKU)
  const [imageURL, setImageURL] = useState(prodImageURL)
  const [title, setTitle] = useState(prodTitle)
  const [unreviewedItems, setUnreviewedItems] = useState(pastOrders)

  const { region: myRegion } = getRegionZone()
  const isMobile = useMediaQuery('(max-width:1024px)')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    if (myRegion && sku) {
      const URL = `${process.env.GATSBY_RECOMMENDATION_SERVICE}/youMayAlsoLike/${sku}?division=${myRegion}`
      fetch(URL)
        .then(response => response.json())
        .then(json => setCarouselSlides(json))
        .catch(err => setCarouselSlides(null))
    }
  }, [myRegion, sku])

  return (
    <Wrapper>
      <SuccessWrapper>
        <SuccessIcon />
        <SuccessTypography data-testid={`${dataTestIdPrefix}success-message`}>
          Review Submitted Successfully!
        </SuccessTypography>
      </SuccessWrapper>

      <ReviewProductInfo
        showYourOrder
        dataTestIdPrefix={`${dataTestIdPrefix}success-`}
        isSuccess
        isMobile={isMobile}
        prodImageURL={prodImageURL}
        prodSKU={sku}
        prodTitle={prodTitle}
      />
      <Divider />

      {unreviewedItems?.length > 0 && (
        <PastOrders
          dataTestIdPrefix={`${dataTestIdPrefix}unreviewed-`}
          setSku={setSku}
          setImageURL={setImageURL}
          setTitle={setTitle}
          searchParams={searchParams}
          orders={unreviewedItems}
          setUnreviewedItems={setUnreviewedItems}
        />
      )}

      {carouselSlides?.length > 0 && (
        <YouMayAlsoLikePaper>
          <YouMayAlsoLikeTypography
            color={palette.primary.dark}
            fontSize="25px"
            textTransform="uppercase"
            fontWeight={500}
          >
            You may also like
          </YouMayAlsoLikeTypography>
          <SliderWrapper>
            <SimpleSlider data={{ heading: '' }} noExtraMargin>
              {carouselSlides.map((item, index) => (
                <ProductTile
                  sku={item.sku}
                  key={`similar_products_to_${item.sku}`}
                  viewType="grid"
                  id={`product-title:${item.sku}`}
                  index={index}
                  showIncludes={false}
                  source="similar-products"
                  route={item.route}
                />
              ))}
            </SimpleSlider>
          </SliderWrapper>
        </YouMayAlsoLikePaper>
      )}
    </Wrapper>
  )
}

ReviewProductSuccess.propTypes = {
  pastOrders: PropTypes.array,
  searchParams: PropTypes.object,
  dataTestIdPrefix: PropTypes.string,
  prodImageURL: PropTypes.string,
  prodSKU: PropTypes.string,
  prodTitle: PropTypes.string,
}

export default ReviewProductSuccess
